import * as React from 'react'
import styles from './FormConfirmationMessagePanel.module.css'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { RouterButton } from '@controls/buttons'
import { PageContent } from '@layout/Page'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import yes from '@assets/images/form/yes.svg'

type Props = Readonly<{
  onReset: () => void
}>

export const FormConfirmationMessagePanel: React.FC<Props> = ({ onReset }) => {
  const { t } = useTranslation()
  const { routes } = useLanguage()

  return (
    <PageContent>
      <div className={styles.confirmationDot}>
        <img src={yes} alt="tick" loading="lazy" className={styles.confirmationImage}/>
      </div>
      <h3 className={styles.confirmationTitle}>{t('global.forms.success.weReceivedYourApplication')}</h3>
      <h4 className={styles.confirmationSubtitle}>{t('global.forms.success.ourTeamWillContactYou')}</h4>
      <ActionButtonsContainer
        justifyContentCenter
        alignItemsCenter
        noTopGap
        verticalOnSm
        fullWidthButtonsOnSm
        responsiveGap
      >
        <RouterButton variant="contained" color="white" to={routes.Index}>
          {t('global.buttons.backToHome')}
        </RouterButton>
        <RouterButton variant="contained" color="blue" onClick={onReset}>
          {t('global.buttons.startOver')}
        </RouterButton>
      </ActionButtonsContainer>
    </PageContent>
  )
}
